/* eslint-disable no-unused-vars */

import React from "react";
import heroThumbOne from "../../../assets/images/Home/home-solar.png";

function HeroHomeOne({ className }) {
  return (
    <>
      <section className={`appie-hero-area  ${className || ""}`} id="home ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 home-description">
              <div className="appie-hero-content">
                <h2 className="appie-title home-title">Digital Energies</h2>
                <h3>
                  Switch to renewable energy and make a difference with Digital
                  Energies.
                </h3>
                <p className="mt-4">
                  Our team of experts offers innovative sustainable energy
                  solutions to meet your needs.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="  ">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={heroThumbOne} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

    // <>
    //     <div className="appie-page-title-area appie-page-service-title-area">
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col-lg-12">
    //                     <div className="appie-page-title-item">
    //                         <span>Fast Development</span>
    //                         <h3 className="title">
    //                             Simple but effective, <br /> effortless yet powerful.
    //                         </h3>
    //                         <div className="thumb">
    //                             <img src={heroThumbOne} alt="" />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </>
  );
}

export default HeroHomeOne;
