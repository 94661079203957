/* eslint-disable */
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";

function Forms() {
  const history = useHistory();
  const [loadBtn, setLoadBtn] = useState(false);
  function sendEmail(e) {
    e.preventDefault();
    setLoadBtn(true);

    console.log(e.target);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          alert(
            "Thank you for your enquiry. Our technical personnel will contact you within 48 – 72 hours."
          );
          setLoadBtn(false);
          history.push("/");
        },
        (error) => {
          console.log(error.text);
          alert("Something went wrong your message could not be sent");
        }
      );
    const formData = new FormData(event.target);
    fetch(
      "https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D3L0000008pyr",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => {
        if (response.ok) {
          // Form submission was successful, you can provide feedback to the user.
          console.log("Form submitted successfully");
        } else {
          // Form submission failed, handle the error.
          console.error("Form submission failed");
        }
      })
      .catch((error) => {
        // Handle any network or other errors.
        console.error("Error:", error);
      });
  }

  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12 order-md-1 order-2">
              <div className="contact--info-area">
                <div className="single-info">
                  <h5>Office Address</h5>
                  <p>
                    <i className="fal fa-home"></i>
                    546 16th Road Randjespark
                    <br /> Midrand, 1685
                  </p>
                </div>
                <div className="single-info">
                  <h5>Phone</h5>
                  <p>
                    <i className="fal fa-phone"></i>
                    (+27) 79 100 5171
                    <br />
                  </p>
                </div>
                <div className="single-info">
                  <h5>Support</h5>
                  <p>
                    <i className="fal fa-envelope"></i>
                    sales@digitalfingerprint.co.za
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 order-md-2 order-1">
              <div className="contact-form">
                <h4>Contact Us</h4>
                <form
                  action="https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D3L0000008pyr"
                  method="POST"
                  onSubmit={sendEmail}
                  className="row"
                >
                  <input type="hidden" name="oid" value="00D3L0000008pyr" />
                  <input
                    type="hidden"
                    name="retURL"
                    value="https://www.digitalfingerprintenergy.co.za/"
                  />
                  {/*Salutation ropdown*/}
                  <div className="col-md-6 col-sm-12">
                    <label for="salutation">Title</label>
                    <select
                      id="salutation"
                      name="salutation"
                      className="custom-dropdown"
                      // onChange="updateDisplay"
                    >
                      {/* <option value="">Salutation</option> */}
                      <option value="">Select option</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Prof.">Prof.</option>
                    </select>
                  </div>
                  {/* First Name */}
                  <div className="col-md-6 col-sm-12">
                    <label for="first_name">First Name</label>
                    <input
                      id="first_name"
                      maxlength="40"
                      name="first_name"
                      size="20"
                      type="text"
                    />
                  </div>
                  {/* Last name */}
                  <div className="col-md-6 col-sm-12">
                    <label for="last_name">Last Name</label>
                    <input
                      id="last_name"
                      maxlength="80"
                      name="last_name"
                      size="20"
                      type="text"
                    />
                  </div>
                  {/* Email */}
                  <div className="col-md-6 col-sm-12">
                    <label for="email">Email</label>
                    <input
                      id="email"
                      maxlength="80"
                      name="email"
                      size="20"
                      type="text"
                    />
                  </div>
                  {/* Company */}
                  <div className="col-md-6 col-sm-12">
                    <label for="company">Company</label>
                    <input
                      id="company"
                      maxlength="40"
                      name="company"
                      size="20"
                      type="text"
                    />
                  </div>
                  {/* State */}
                  <div className="col-md-6 col-sm-12">
                    <label for="state">Province</label>
                    <input
                      id="state"
                      maxlength="20"
                      name="state"
                      size="20"
                      type="text"
                    />
                  </div>
                  {/* Phone number */}
                  <div className="col-md-6 col-sm-12">
                    <label for="phone">Phone</label>
                    <input
                      id="phone"
                      maxlength="40"
                      name="phone"
                      size="20"
                      type="text"
                    />
                  </div>
                  {/* Street */}
                  <div className="col-md-6 col-sm-12">
                    <label for="street">Street</label>
                    <input
                      id="street"
                      maxlength="40"
                      name="street"
                      size="20"
                      type="text"
                      // placeholder="Street"
                    />
                  </div>
                  {/* Drop down */}
                  {/* Zip */}
                  <div className="col-md-4 col-sm-12">
                    <label for="zip">Zip Code</label>
                    <input
                      id="zip"
                      maxlength="40"
                      name="zip"
                      size="20"
                      type="text"
                      // placeholder="Zip"
                    />
                    <input
                      type="hidden"
                      id="lead_source"
                      name="lead_source"
                      value="Web"
                    />
                  </div>
                  <input
                    type="hidden"
                    id="lead_source"
                    name="lead_source"
                    value="Web"
                  />
                  {/* City */}
                  <div className="col-md-4 col-sm-12">
                    <label for="city">City</label>
                    <input
                      id="city"
                      maxlength="40"
                      name="city"
                      size="20"
                      type="text"
                      // placeholder="City"
                    />
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label for="What UPS size are you looking to buy ?">
                      What UPS size are you looking to buy?
                    </label>
                    <select
                      name="What UPS size are you looking to buy"
                      className="custom-dropdown"
                      id="What UPS size are you looking to buy"
                      // onChange="updateDisplay"
                    >
                      <option value="">Select Option</option>
                      <option value="1 KVA">1 KVA</option>
                      <option value="2 KVA">2 KVA</option>
                      <option value="3 KVA">3 KVA</option>
                      <option value="6 KVA">6 KVA</option>
                      <option value="10 KVA">10 KVA</option>
                      <option value="20 KVA">20 KVA</option>
                      <option value="30 KVA">30 KVA</option>
                      <option value="40 KVA">40 KVA</option>
                    </select>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label for="What is your desired runtime ?">
                      What is your desired runtime ?
                    </label>
                    <select
                      name="What is your desired runtime"
                      className="custom-dropdown"
                      id="What is your desired runtime"
                      // onChange="updateDisplay"
                    >
                      <option value="">Select Option</option>
                      <option value="10-20Minutes">10-20 Minutes</option>
                      <option value="60-120 Minutes">60-120 Minutes</option>
                      <option value="150-240 Minutes">150-240 Minutes</option>
                    </select>
                  </div>

                  <div className="col-md-12 col-sm-12">
                    <label for="Installation Required ?">
                      Installation Required ?
                    </label>
                    <select
                      name="Installation Required ?"
                      className="custom-dropdown"
                      id="Installation Required ?"
                      title="Installation Required ?"
                      // onChange="updateDisplay"
                    >
                      <option value="">Select option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <div className="col-md-6 col-sm-12"></div>
                  <div className="col-md-6 col-sm-12 col-lg-6 text-right">
                    <input
                      type="submit"
                      name="submit"
                      value={loadBtn ? "Loading..." : "Submit"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bisylms-map">
        <iframe
          title="map"
          src="https://maps.google.com/maps?q=546 16 Road Randjestpark midrand 1685&t=&z=10&ie=UTF8&iwloc=&output=embed"
        ></iframe>
      </div>
    </>
  );
}

export default Forms;
