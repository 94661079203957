/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../../components/BackToTop';
import FooterHomeOne from '../Home/sections/FooterHomeOne';
import ProjectHomeOne from '../Home/sections/ProjectHomeOne';
import Drawer from '../../components/Mobile/Drawer';
import DetailsService from './sections/DetailsService';
import HeaderService from './components/HeaderService';
import HeroService from './components/HeroService';

function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderService action={drawerAction.toggle} />
            <HeroService />
            <DetailsService />
            <ProjectHomeOne />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Service;
