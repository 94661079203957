/* eslint-disable no-unused-vars */

import React from "react";
import { Link } from "react-router-dom";
import heroThumbOne from "../../../assets/images/Home/home-solar.png";

function HeroNews({ title, breadcrumb = [] }) {
  return (
    <>
      <div className="appie-page-title-area">
        <div className="container ">


          <div className=" appie-page-title-item col-md-12">

            <div className="row">

              <div className="col-md-6 home-description">
                <h3 className="title my_title">{title}</h3>
                <nav aria-label="breadcrumb ">
                  <ol className="breadcrumb ">
                    {breadcrumb.map((value) => (
                      <li key={Math.random()} className="breadcrumb-item ">
                        <Link to={value.link} className="my_breadcrum">
                          {value.title}
                        </Link>
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>

              <div className="col-md-6">
                <img src={heroThumbOne} alt="" />
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default HeroNews;
