/* eslint-disable no-unused-vars */
import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../../components/BackToTop';
import Drawer from '../../components/Mobile/Drawer';
import FooterHomeOne from './sections/FooterHomeOne';
import HeroHomeOne from './sections/HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import PackagesSections from './sections/PackagesSections';
import ProjectHomeOne from './sections/ProjectHomeOne';
import WhySection from './sections/WhySection';
import ServiceSection from './sections/ServiceSection';
import AboutSection from './sections/AboutSection';
import BlogHomeOne from './sections/BlogHomeOne';
import TeamHomeOne from './sections/TeamHomeOne';
import FaqHomeOne from './sections/FaqHomeOne';
import TestimonialHomeOne from './sections/TestimonialHomeOne';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeOne />
            <AboutSection />
            <WhySection />
            <ServiceSection />
            <PackagesSections />
            <ProjectHomeOne />
            {/* <BlogHomeOne /> */}
            {/* <TeamHomeOne /> */}
            {/* <FaqHomeOne /> */}
            {/* <FeaturesHomeOne /> */}
            {/* <TestimonialHomeOne /> */}
            {/* <TestimonialHomeOne /> */}
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
