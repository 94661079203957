/* eslint-disable no-unused-vars */

import React from "react";
import inerters1 from "../../../assets/images/products/inerters1.jpg";
import energy1 from "../../../assets/images/products/energy1.jpg";
import solar1 from "../../../assets/images/products/solar1.jpg";

function ProductSectionOne({ className }) {
  return (
    <section className={`appie-service-area pt-90 pb-100 ${className}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="appie-section-title text-center">
              {" "}
              <h3 className="appie-title">Our products</h3>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 desktop-only">
            <div
              className="appie-single-service  mt-30 wow animated fadeInUp service-box"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="">
                {" "}
                <img src={inerters1} alt="" />{" "}
              </div>
              <h4 className="appie-title"> Inverters and UPS </h4>
              <p>
                {" "}
                Our Sine Wave Inverters can efficiently produce Pure Sine Wave
                Output in a regulated manner. As a complete and extremely
                capable power generation system, these inverters and UPS can be
                used to run lights, fans, ACs, and even heavy machinery across
                locations such as your home, shops, commercial spaces and
                industrial spaces. These are smartly designed, cost-effective &
                environment-friendly.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 desktop-only">
            <div
              className="appie-single-service  mt-30 item-2 wow animated fadeInUp service-box"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className=" ">
                <img src={energy1} alt="" />
              </div>
              <h4 className="appie-title"> Solar System </h4>
              <p>
                {" "}
                Our wide array of Solar expertise includes On-Grid & Off-Grid
                Rooftop Solutions, Solar EPC Solutions, Solar Charge
                Controllers, Solar Hybrid Power Packs, Solar PV Panels,
                Inverters & UPS and Net Metering Solutions. All these are
                eco-friendly, have versatile applications, are easy to use, and
                highly energy-efficient. Superior design quality, high
                performance and affordability are other advantages{" "}
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 desktop-only">
            <div
              className="appie-single-service  mt-30 item-3 wow animated fadeInUp service-box"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="">
                <img src={solar1} alt="" />
              </div>
              <h4 className="appie-title"> Battery Energy Storage Systems </h4>
              <p>
                {" "}
                An Energy Storage System (ESS) can be defined as a configuration
                of equipment with the sole purpose of enabling the storage of
                energy onto a defined medium. The basic configuration is that of
                storing excess energy from the grid onto the Battery Energy
                Storage System (BESS) and then utilizing it, either to defined
                intervals or when there is a power outage. We offer a wide range
                of BESS systems to a spectrum of our client's needs and
                requirements.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductSectionOne;
