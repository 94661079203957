/* eslint-disable no-unused-vars */

import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../../components/BackToTop';
import FooterHomeOne from '../Home/sections/FooterHomeOne';
import Drawer from '../../components/Mobile/Drawer';
import HeroNews from '../News/components/HeroNews';
import HeaderNews from '../News/components/HeaderNews';
import SectionOne from './sections/sectionOne';

function AboutUsTwo() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews action={drawerAction.toggle} />
            <HeroNews title="Products" breadcrumb={[{ link: '/', title: 'Home' }, { link: '/products', title: 'Products' },]} />
            <SectionOne />
            <FooterHomeOne className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default AboutUsTwo;
