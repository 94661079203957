/* eslint-disable no-unused-vars */

import React from "react";

function Navigation() {
  return (
    <>
      <ul>
        <li>
          <a href="#about">About Us</a>
        </li>
        <li>
          <a href="#service">Services</a>
        </li>
        <li>
          <a href="/products">Products</a>
        </li>
        <li>
          <a href="/contact">Contact</a>
        </li>
      </ul>
    </>
  );
}

export default Navigation;
