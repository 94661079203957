/* eslint-disable no-unused-vars */

import React from "react";
import electricity1 from "../../../assets/images/why-section/electricity1.png";
import ready1 from "../../../assets/images/why-section/ready1.png";
import savePlanet1 from "../../../assets/images/why-section/save-planet1.png";
import value1 from "../../../assets/images/why-section/value1.png";

function WhySection({ className }) {
  return (
    <section className={`appie-service-area pt-90 pb-100 ${className}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="appie-section-title text-center">
              <h3 className="appie-title">Digital Energies Solutions</h3>
              <p> Why Digital Energies? </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 desktop-only">
            <div
              className="appie-single-service text-center mt-30 wow animated fadeInUp service-box"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className=" text-center">
                <img src={electricity1} alt="" width="200" />
              </div>
              <h4 className="appie-title">Save on electricity Bills</h4>
              <p>
                {" "}
                Solar PV is the perfect way of protecting yourself high
                electricity bills. Switch to Solar panels & save up on your
                bills
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 desktop-only">
            <div
              className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp service-box"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className=" text-center">
                <img src={ready1} alt="" width="200" />
              </div>
              <h4 className="appie-title"> Ready when needed </h4>
              <p>
                {" "}
                Generate your power and manage your use, even when the grid goes
                down! Survive during blackouts{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 desktop-only">
            <div
              className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp service-box"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className=" text-center">
                <img src={savePlanet1} alt="" width="200" />
              </div>
              <h4 className="appie-title">Save the planet</h4>
              <p>
                {" "}
                A renewable form of energy, solar offers a clean, green economic
                way of reducing your carbon footprint. Go Green & save the
                planet{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 desktop-only">
            <div
              className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp service-box"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className=" text-center">
                <img src={value1} alt="" width="200" />
              </div>
              <h4 className="appie-title">Increase Value</h4>
              <p>
                {" "}
                The solar system is a great way of investing and increasing the
                value of your property. Upgrade your property value
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhySection;
