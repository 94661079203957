/* eslint-disable no-unused-vars */
import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../../components/BackToTop";
import FooterHomeOne from "../Home/sections/FooterHomeOne";
import Drawer from "../../components/Mobile/Drawer";
import HeaderNews from "../News/components/HeaderNews";
import HeroNews from "../News/components/HeroNews";
import Forms from "./sections/Forms";

function Contact() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HeaderNews drawer={drawer} action={drawerAction.toggle} />
      <HeroNews
        title="Contact"
        breadcrumb={[
          { link: "/", title: "home" },
          { link: "/contact", title: "Contact Us " },
        ]}
      />

      <Forms />
      <FooterHomeOne />
      <BackToTop />
    </>
  );
}

export default Contact;
