/* eslint-disable no-unused-vars */

import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../../components/BackToTop';
import FooterHomeOne from '../Home/sections/FooterHomeOne';
import Drawer from '../../components/Mobile/Drawer';
import HeaderNews from './components/HeaderNews';
import HeroNews from './components/HeroNews';
import Blogs from './Detail-page/Blog';


function News() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews action={drawerAction.toggle} />
            <HeroNews title="Blogs" breadcrumb={[{ link: '/', title: 'home' }, { link: '/news', title: 'Blogs' },]} />
            <section className="blogpage-section">
                <div className="container">  <Blogs />  </div>
            </section>
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default News;
