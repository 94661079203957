/* eslint-disable no-unused-vars */
import React from "react";
import logo from "../../assets/images/Giantech_logo_header1.png";

function Drawer({ drawer, action }) {
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <div className="canvas_close">
                  {" "}
                  <a href="#" onClick={(e) => action(e)}>
                    {" "}
                    <i className="fa fa-times"></i>{" "}
                  </a>{" "}
                </div>
                <div className="offcanvas-brand text-center mb-40">
                  {" "}
                  <img src={logo} alt="" />{" "}
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li>
                      {" "}
                      <a href="#home">Home</a>{" "}
                    </li>
                    <li>
                      <a href="#about">About Us</a>
                    </li>
                    <li>
                      <a href="#service">Service</a>
                    </li>
                    <li>
                      <a href="/products">Products</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget-info">
                  <ul>
                    <li>
                      <a href="#">
                        {" "}
                        <i className="fal fa-envelope"></i>{" "}
                        sales@digitalfingerprint.co.za{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i className="fal fa-phone"></i> +(27) 791005171{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i className="fal fa-map-marker-alt"></i>546 16th Road
                        Randjespark Midrand, 1685
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
