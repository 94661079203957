/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//* Pages
import HomePage from "./Pages/Home";
import AboutUs from "./Pages/Products";
import Service from "./Pages/Service/index";
import Products from "./Pages/Products/index";
import Contact from "./Pages/Contact/index";
import NewsBlogs from "./Pages/News/index";
import NewsDetail from "./Pages/News/details-blogs";
import Shop from "./Pages/Shops/index";
import Error from "./Pages/Error";

//* Components
import Loader from "./components/Helper/Loader";
import ScrollToTop from "./components/Helper/ScrollToTop";

function Routes() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });

  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? "active" : ""}`}>
          <Loader />
        </div>
      )}

      <div className={`appie-visible ${loading === false ? "active" : ""}`}>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/service" component={Service} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/products" component={Products} />
              <Route exact path="/blogs" component={NewsBlogs} />
              <Route exact path="/shop" component={Shop} />
              <Route exact path="/shop-details/:id" component={NewsDetail} />
              <Route component={Error} />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    </>
  );
}

export default Routes;
