/* eslint-disable no-unused-vars */

import React from "react";
import logo from "../../../assets/images/Giantech_logo_header1.png";

function FooterHomeOne({ className }) {
  return (
    <>
      <section className={`appie-footer-area ${className || ""}`} id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <p>
                  Solar panel technology is a solar panel system that utilizes
                  renewable organic materials to produce energy.
                </p>

                <div className="social mt-30">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100088701728948">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Menus</h4>
                <ul>
                  <li>
                    <a href="#home" to="/">
                      Home{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#about" to="/">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#service" to="/">
                      Service
                    </a>
                  </li>
                  <li>
                    <a href="/products" to="/">
                      Products
                    </a>
                  </li>
                  <li>
                    <a href="/contact" to="/">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope" />{" "}
                      sales@digitalfingerprint.co.za
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone" /> +(27) 79 100 5171
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-map-marker-alt" /> 546 16th Road
                      Randjespark Midrand, 1685
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                <div className="copyright-text">
                  <p>
                    © Copyright 2024 build with love ❤️ by{" "}
                    <a href="https://www.websitebuilders.dev">
                      https://www.websitebuilders.dev
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeOne;
