/* eslint-disable no-unused-vars */

import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../../components/BackToTop';
import FooterHomeOne from '../Home/sections/FooterHomeOne';
import Drawer from '../../components/Mobile/Drawer';
import Blogs from './sections/Blogs';
import BlogSideBar from './components/BlogSideBar';
import HeaderNews from './components/HeaderNews';
import HeroNews from './components/HeroNews';

function News() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews action={drawerAction.toggle} />
            <HeroNews title="Blogs" breadcrumb={[{ link: '/', title: 'home' }, { link: '/news', title: 'Blogs' },]} />
            <section className="blogpage-section">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-7"> <Blogs /> </div>

                        <div className="col-lg-4 col-md-5"> <BlogSideBar /> </div>
                    </div>
                </div>

            </section>
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default News;
